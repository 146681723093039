import React from "react"
import Card from "./CommunityCard"
import { Container, Row, Col } from "react-bootstrap"

export default ({ label = null, items }) => {
  return (
    <Container className={"px-0 resource-cards pt-5 dark-mode"}>
      <Row>
        {items &&
          items.map((item, i) => {
            return (
              <Col
                md={6}
                lg={4}
                key={"resource-col-" + i}
                className={"pb-4 d-flex"}
                data-sal="slide-up"
                data-sal-duration="800"
                data-sal-delay="1200"
                data-sal-easing="ease"
              >
                <Card
                  key={"dark mode resource-card-" + i}
                  {...item}
                  label="See their story"
                />
              </Col>
            )
          })}
      </Row>
    </Container>
  )
}
