import React from "react"
import { Card, Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Button from "./../Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import "./style.scss"

export default ({
  relationships,
  title,
  caption,
  location,
  label,
  isFeatured = false,
}) => {
  const slug = encodeURIComponent(
    title
      .toLowerCase()
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/\s/g, "-")
  )
  const imageThumb =
    relationships?.field_image?.relationships?.field_media_image?.thumbnail
  const imageFeatured =
    relationships?.field_image?.relationships?.field_media_image?.featuredImage

  let defaultClasses = "card community resource"

  const TiledCard = ({ className }) => (
    <Link
      to={`/first-responders/community-detail/${slug}`}
      className={className ? `${defaultClasses} ${className}` : defaultClasses}
    >
      <Img
        className={"resource-img-card"}
        key={"img-thumb-" + imageThumb.childImageSharp.fixed.originalName}
        loading={"lazy"}
        fixed={imageThumb.childImageSharp.fixed}
        alt={title}
      />
      <Container className={"labels"} fluid>
        <Row className={"pt-3 px-3 align-items-center"}>
          <Col>
            <Card.Text className={"source-location"}>{location}</Card.Text>
          </Col>
        </Row>
      </Container>
      <Card.Title className="p-3 mb-0">{title}</Card.Title>
      <Card.Text className={"px-3 caption"}>{caption}</Card.Text>
      <Container className={"action-links"} fluid={true}>
        <Row>
          <Col>
            <Button
              icon={`arrow-right`}
              variant={"btn-transparent-red"}
              className={"btn btn-transparent-red"}
              label={label}
            />
          </Col>
        </Row>
      </Container>
    </Link>
  )

  if (isFeatured) {
    return (
      <>
        <Card
          className={"featured alt d-none d-md-block"}
          data-sal="slide-up"
          data-sal-duration="1200"
          data-sal-delay="1200"
          data-sal-easing="ease"
        >
          <Link to={`/first-responders/community-detail/${slug}`}>
            <Img
              key={
                "img-featured-" +
                imageFeatured.childImageSharp.fixed.originalName
              }
              loading={"lazy"}
              fixed={imageFeatured.childImageSharp.fixed}
              alt={title}
            />
            <Card.ImgOverlay className={"pl-0"}>
              <Col md={6} className={"position-absolute pb-0 pb-lg-3"}>
                <Card.Title>{title}</Card.Title>
                <Card.Text className={"caption"}>{caption}</Card.Text>
                <p className={"card-link mb-0 pb-lg-3"}>
                  <FontAwesomeIcon icon={"arrow-right"} />
                  {label}
                </p>
              </Col>
            </Card.ImgOverlay>
          </Link>
        </Card>
        <TiledCard className={"p-0 dark-mode d-md-none"} />
      </>
    )
  } else {
    return <TiledCard className={"p-0 dark-mode"} />
  }
}
